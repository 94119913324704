import { useEffect } from "react";
import GuestLayout from "@/Layouts/GuestLayout";
import { Head, Link, useForm } from "@inertiajs/react";
import { Button } from "@/Components/Catalyst/button";
import { ErrorMessage, Field, Label } from "@/Components/Catalyst/fieldset";
import { Input } from "@/Components/Catalyst/input";
import { Checkbox, CheckboxField } from "@/Components/Catalyst/checkbox";
import { FormattedMessage, useIntl } from "react-intl";
import { Divider } from "@/Components/Catalyst/divider";
import { Text, TextLink } from "@/Components/Catalyst/text";

export default function Login({ status, canResetPassword }) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: "",
        password: "",
        remember: false,
    });

    useEffect(() => {
        return () => {
            reset("password");
        };
    }, []);

    const submit = (e) => {
        e.preventDefault();

        post(route("login"));
    };

    const { formatMessage } = useIntl();
    return (
        <GuestLayout>
            <Head
                title={formatMessage({
                    id: "outer.pages.auth.login.title",
                })}
            />

            {status && (
                <div className="mb-4 text-sm font-medium text-green-600">
                    {status}
                </div>
            )}

            <form onSubmit={submit}>
                <div>
                    <Field>
                        <Label>
                            <FormattedMessage id="outer.pages.auth.login.email" />
                        </Label>
                        <Input
                            name="email"
                            type="email"
                            value={data.email}
                            onChange={(e) => setData("email", e.target.value)}
                            invalid={!!errors?.email} // Change invalid prop to !!errors?.name
                        />
                        {errors?.email && (
                            <ErrorMessage>{errors.email}</ErrorMessage>
                        )}
                    </Field>
                </div>

                <div className="mt-4">
                    <Field>
                        <Label>
                            <FormattedMessage id="outer.pages.auth.login.password" />
                        </Label>
                        <Input
                            name="password"
                            type="password"
                            value={data.password}
                            onChange={(e) =>
                                setData("password", e.target.value)
                            }
                            invalid={!!errors?.password}
                        />
                        {errors?.password && (
                            <ErrorMessage>{errors.password}</ErrorMessage>
                        )}
                    </Field>
                </div>

                <div className="block mt-4">
                    <CheckboxField>
                        <Checkbox
                            name="remember"
                            checked={data.remember}
                            onChange={(e) => setData("remember", e)}
                        />
                        <Label>
                            <FormattedMessage id="outer.pages.auth.login.remember_me" />
                        </Label>
                    </CheckboxField>
                </div>

                <div className="flex items-center justify-end mt-4">
                    {canResetPassword && (
                        <Button outline href={route("password.request")}>
                            <FormattedMessage id="outer.pages.auth.login.forgot_password" />
                        </Button>
                    )}

                    <Button color="dark" className="ms-4" disabled={processing}>
                        <FormattedMessage id="outer.pages.auth.login.login" />
                    </Button>
                </div>
                <Divider className="my-4" />
                <Text className="text-center">
                    <FormattedMessage id="outer.pages.auth.login.no_account" />{" "}
                    <TextLink href={route("register")}>
                        <FormattedMessage id="outer.pages.auth.login.register" />
                    </TextLink>
                </Text>
            </form>
        </GuestLayout>
    );
}
